import { render, staticRenderFns } from "./day-collection-statistic.vue?vue&type=template&id=da1f6e84&scoped=true"
import script from "./day-collection-statistic.vue?vue&type=script&lang=ts"
export * from "./day-collection-statistic.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da1f6e84",
  null
  
)

export default component.exports