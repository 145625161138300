import axios from "axios";
var HttpRequest = /** @class */ (function () {
    function HttpRequest() {
        // this.baseUrl = "http://localhost:9900/ddlegal";
        this.baseUrl = "https://msapi.liansdk.com/ddlegal";
    }
    HttpRequest.prototype.getInsideConfig = function () {
        return {
            baseURL: this.baseUrl,
            timeout: 80000,
            withCredentials: true,
        };
    };
    // 请求拦截
    HttpRequest.prototype.interceptors = function (instance, url) {
        instance.interceptors.request.use(function (config) {
            // 添加全局的loading..
            // 请求头携带token
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
        //响应拦截
        instance.interceptors.response.use(function (res) {
            //返回数据
            var data = res.data;
            console.log("返回数据处理", res);
            return data;
        }, function (error) {
            console.log("error==>", error);
            return Promise.reject(error);
        });
    };
    HttpRequest.prototype.request = function (options) {
        var instance = axios.create();
        options = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, options.url);
        return instance(options);
    };
    HttpRequest.prototype.get = function (url, params) {
        return this.request({
            url: url,
            method: "get",
            params: params,
        });
    };
    HttpRequest.prototype.post = function (url, data) {
        return this.request({
            url: url,
            method: "post",
            data: data,
        });
    };
    HttpRequest.prototype.postDownload = function (url, data) {
        return this.request({
            url: url,
            method: "post",
            data: data,
            responseType: 'blob'
        });
    };
    HttpRequest.prototype.getDownload = function (url, params) {
        return this.request({
            url: url,
            method: "get",
            params: params,
            responseType: 'blob'
        });
    };
    return HttpRequest;
}());
var legalWanRequest = new HttpRequest();
export default legalWanRequest;
